import React from 'react';
import './SDKList.css';  // External CSS file

const SDKList = () => {
  return (
    <div className="sdk-list-container">
      <h2>第三方SDK清单</h2>

      <div className="sdk-item">
        <h3>1. 第三方SDK名称：友盟+</h3>
        <p>运营者信息：友盟同欣（北京）科技有限公司</p>
        <p>处理个人信息类型：设备信息 (Android ID/IDFA/OAID/OpenUDID/GUID/IMEI/IMSI/ICCID)、网络信息</p>
        <p>使用目的的说明：Bug 统计分析，推送服务及基础反作弊服务</p>
        <a href="https://www.umeng.com/page/policy">隐私政策链接</a>
      </div>
    </div>
  );
};

export default SDKList;