import React from 'react';

function SelfPrivacy() {
    return (
        <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <iframe
                src="/lure_self_privacy.html"
                style={{
                    border: 'none',
                    display: 'block',
                    width: '100%',
                    height: '100%'
                }}
                title="路亚大师隐私协议"
            />
        </div>
    );
}

export default SelfPrivacy;