import React from 'react';
import './PermissionsList.css';

const PermissionsList = () => {
  return (
    <div className="permissions-container">
      <h1>路亚大师设备权限清单</h1>

      <ul className="permissions-list">
        <li>
          <strong>1) 电话-设备信息权限：</strong>
          为确保基本功能的实现，我们会调用您设备的电话-设备信息权限，获取您的设备识别码（包括IMSI、IMEI、AndroidID），以便用于统计及防作弊等。
        </li>
      </ul>
    </div>
  );
};

export default PermissionsList;