import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="container">
      <p>路亚大师App，专注于路亚领域，提供钓鱼人专业级功能，包含筛鱼、钓获总结、装备管理等等，欢迎钓友到应用商店下载。</p>
      <button className="button">了解更多</button>
    </div>
  );
};

export default Home;