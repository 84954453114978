import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from '../pages/Home';
import SDKList from '../pages/SDKList';
import PermissionsList from '../pages/PermissionsList';
import './App.css';  // External CSS for styles
import logo from '../assets/app-logo.png';
import LurePrivacy
 from '../pages/LurePrivacy';
import OtherSdkPrivacy from '../pages/OhterSdkPrivacy';
import PermissionsPrivacy from '../pages/PermissionsPrivacy';
import SelfPrivacy from '../pages/SelfPrivacy';
import UserPrivacy from '../pages/UserPrivacy';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Router>
      <div className="app-container">
        <header className="header">
          <button className="menu-button" onClick={toggleMenu}>
            ☰
          </button>
          <h1>路亚大师App</h1>
          <img src={logo} alt="路亚大师App Logo" className="app-logo" /> {/* 使用 logo 图片 */}
        </header>

        <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/" onClick={toggleMenu}>首页</Link></li>
            {/* <li><Link to="/sdk-list" onClick={toggleMenu}>第三方 SDK 清单</Link></li>
            <li><Link to="/permissions-list" onClick={toggleMenu}>设备权限清单</Link></li> */}

            <li><Link to="/lureprivacy/privacy" onClick={toggleMenu}>隐私政策</Link></li>
            <li><Link to="/lureprivacy/sdk" onClick={toggleMenu}>第三方SDK清单</Link></li>
            <li><Link to="/lureprivacy/devices" onClick={toggleMenu}>设备权限清单</Link></li>
            <li><Link to="/lureprivacy/personal" onClick={toggleMenu}>个人信息清单</Link></li>
            <li><Link to="/lureprivacy/user" onClick={toggleMenu}>用户协议</Link></li>
          </ul>
        </nav>

        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sdk-list" element={<SDKList />} />
            <Route path="/permissions-list" element={<PermissionsList />} />

            {/* 跳转到静态的 HTML 页面 */}
            <Route path="/lureprivacy/privacy" element={<LurePrivacy />} />
            <Route path="/lureprivacy/sdk" element={<OtherSdkPrivacy />} />
            <Route path="/lureprivacy/devices" element={<PermissionsPrivacy />} />
            <Route path="/lureprivacy/personal" element={<SelfPrivacy />} />
            <Route path="/lureprivacy/user" element={<UserPrivacy />} />

          </Routes>
        </main>

        <footer className="footer">
          <p>&copy; 2024 路亚大师App / 京ICP备2024088951号-2</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;